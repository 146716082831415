<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-textarea
        v-if="type == 'textarea'"
        size="small"
        :value="value"
        @change="handleChange"
        @pressEnter="check"
        :suffix="suffixTxt"
      />
      <a-input
        v-if="type == 'input'"
        placeholder="请输入"
        :value="value"
        @change="handleChange"
        @pressEnter="check"
      />
      <a-icon type="check" class="editable-cell-icon-check" @click="check" />
    </div>
    <div v-else class="editable-cell-text-wrapper">
      {{ value || " " }}
      <a-icon type="edit" class="editable-cell-icon" @click="edit" />
    </div>
  </div>
</template>
<script>
export default {
  name: "EditTableCell",
  props: {
    text: String,
    suffixTxt: String,
    type: {
      type: String,
      default: () => "input",
    },
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  watch: {
    text(val) {
      this.value = val;
    },
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      this.editable = true;
    },
  },
};
</script>