<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="articleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row v-if="form.auditStatus == 3">
          <a-form-model-item label="审核意见">
            {{ form.auditRemark }}
          </a-form-model-item>
        </a-row>
        <a-form-model-item
          label="所属分类"
          prop="categoryId"
          :help="
            info.specialTypeId
              ? '提示：科学研究，分享个人研究动态与心得；生活日常，分享 个人生活感悟与瞬间；社会团体，分享工会、妇联等社团活动'
              : ''
          "
          class="custom-form-explain"
        >
          <a-cascader
            placeholder="请选择"
            allowClear
            :options="categoryTree"
            v-model="form.categoryId"
            :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
          />
        </a-form-model-item>
        <a-form-model-item label="资讯标题" prop="title">
          <a-input placeholder="请输入" v-model="form.title" :maxLength="100" />
        </a-form-model-item>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="资讯类型"
              prop="articleType"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 15 }"
            >
              <a-radio-group v-model="form.articleType">
                <template v-for="type in articleTypeList">
                  <a-radio :value="type.type" :key="type.type">{{
                    type.name
                  }}</a-radio>
                </template>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="发布时间"
              prop="publishTime"
              :wrapper-col="{ span: 14 }"
            >
              <a-date-picker
                @change="timeChange"
                v-model="form.publishTime"
                style="width: 100%"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <!--  <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="标签"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 15 }"
            >
              <a-select v-model="form.tag" placeholder="请选择" mode="multiple">
                <template v-for="tag in tagList">
                  <a-select-option :value="tag.itemName" :key="tag.id">
                    {{ tag.itemName }}
                  </a-select-option>
                </template>
              </a-select>
            </a-form-model-item>
                    </a-col>
        <a-col :span="12">
            <a-form-model-item
              label="推荐截止时间"
              prop="cancelTime"
              :wrapper-col="{ span: 14 }"
            >
              <a-date-picker
                v-model="form.cancelTime"
                :disabled-date="disabledDateTime"
              >
                <template slot="renderExtraFooter">
                  <span @click="presetDate(3)">三天后</span>
                  <span @click="presetDate(5)">五天后</span>
                  <span @click="presetDate(7)">一周后</span>
                  <span @click="presetDate(30)">一个月后</span>
                </template>
              </a-date-picker>
            </a-form-model-item>
                    </a-col>
        </a-row>-->
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="作者"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 15 }"
            >
              <a-input
                placeholder="请输入"
                v-model="form.author"
                :maxLength="50"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="来源" :wrapper-col="{ span: 14 }">
              <a-input
                placeholder="请输入"
                v-model="form.source"
                :maxLength="200"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="概述" prop="summary">
          <a-input
            placeholder="请输入"
            v-model="form.summary"
            type="textarea"
            :maxLength="200"
          />
        </a-form-model-item>
        <!--        <a-form-model-item label="封面图类型" prop="pictureType">-->
        <!--          <a-radio-group v-model="form.pictureType">-->
        <!--            <template v-for="type in pictureTypeList">-->
        <!--              <a-radio :value="type.type" :key="type.type">-->
        <!--                {{ type.name }}-->
        <!--              </a-radio>-->
        <!--            </template>-->
        <!--          </a-radio-group>-->
        <!--        </a-form-model-item>-->
        <!--        <a-form-model-item label="封面图" v-if="form.pictureType > 0">-->
        <!--          <a-upload-->
        <!--            accept="image/png, image/jpeg"-->
        <!--            listType="picture-card"-->
        <!--            :action="uploadFileUrl"-->
        <!--            :fileList="fileList"-->
        <!--            :beforeUpload="beforeUpload"-->
        <!--            @preview="handlePreview"-->
        <!--            @change="handleFileChange"-->
        <!--          >-->
        <!--            <div-->
        <!--              v-if="-->
        <!--                (form.pictureType != 4 && fileList.length == 0) ||-->
        <!--                (form.pictureType == 4 && fileList.length < 3)-->
        <!--              "-->
        <!--            >-->
        <!--              <a-icon type="plus" />-->
        <!--              <div class="ant-upload-text">上传</div>-->
        <!--            </div>-->
        <!--          </a-upload>-->
        <!--          <a-modal-->
        <!--            :visible="previewVisible"-->
        <!--            :footer="null"-->
        <!--            @cancel="handleCancel"-->
        <!--          >-->
        <!--            <img alt="example" style="width: 100%" :src="previewImage" />-->
        <!--          </a-modal>-->
        <!--        </a-form-model-item>-->
        <a-form-model-item label="视频" v-if="form.articleType == 2">
          <a-upload
            accept="video/mp4"
            :action="uploadFileUrl"
            :fileList="videoFileList"
            :beforeUpload="beforeVideoUpload"
            @change="handleVideoFileChange"
          >
            <a-button> <a-icon type="upload" />上传 </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="图集" v-if="form.articleType == 3">
          <a-upload
            accept="image/png, image/jpeg"
            :action="uploadFileUrl"
            :showUploadList="false"
            :beforeUpload="beforeUpload"
            @change="handleImgListChange"
          >
            <a-button :loading="imgUploading">
              <a-icon type="upload" />上传
            </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="图集列表" v-if="form.articleType == 3">
          <a-table
            rowKey="sortNo"
            bordered
            size="small"
            :columns="imgTable.columns"
            :pagination="false"
            :dataSource="form.imgList"
          >
            <template slot="path" slot-scope="path">
              <img style="width: 160px" :src="path" />
            </template>
            <template slot="introduce" slot-scope="record">
              <editable-cell
                :text="record.introduce"
                @change="onCellChange(record.sortNo, 'introduce', $event)"
              />
            </template>
            <span slot="action" slot-scope="record">
              <p-button
                name="上移"
                size="small"
                type="link"
                @click="handleUp(record.sortNo)"
                v-if="record.sortNo > 1"
              />
              <p-button
                name="下移"
                size="small"
                type="link"
                @click="handleDown(record.sortNo)"
                v-if="record.sortNo < form.imgList.length"
              />
              <a-popconfirm
                title="确定要删除吗?"
                @confirm="() => removeImg(record.sortNo)"
              >
                <p-button
                  name="刪除"
                  size="small"
                  type="link"
                  style="color: #ff4d4f"
                />
              </a-popconfirm>
            </span>
          </a-table>
        </a-form-model-item>
        <a-form-model-item
          label="内容"
          prop="content"
          v-show="form.articleType == 1"
        >
          <span style="color: red">提示：内容上传附件仅支持图片和PDF文档</span>
          <vue-ueditor-wrap
            editor-id="content"
            :config="myConfig"
            v-model="content"
          ></vue-ueditor-wrap>
        </a-form-model-item>
        <a-row>
          <a-col :span="8">
            <a-form-model-item
              label="是否推荐"
              :label-col="{ span: 12 }"
              :wrapper-col="{ span: 8 }"
            >
              <a-switch
                v-model="form.recommend"
                checked-children="是"
                un-checked-children="否"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="排序号"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 8 }"
            >
              <a-input
                placeholder="请输入"
                v-model="form.priority"
                :maxLength="10"
              />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-form-model-item :wrapper-col="{ span: 19, offset: 4 }">
          <p-button
            name="审核"
            type="primary"
            style="margin-right: 10px"
            @click="showaudit()"
            v-if="form.processExecuteStatus && form.taskKey == '文章-发布'"
          />

          <p-button
            name="暂存"
            v-if="form.processStatus == null || form.processStatus == 0"
            type="primary"
            @click="handleSubmit(0)"
            style="margin-right: 10px"
          />
          <p-button
            name="提交"
            type="danger"
            v-if="form.processStatus == null || form.processStatus == 0"
            style="margin-right: 10px"
            @click="handleSubmit(1)"
          />
          <p-button name="取消" @click="cancelClick" />
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-modal
      title="审批"
      v-model="auditModal.visible"
      width="40%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <audit :info="auditModal.info" @callback="auditCallBack" />
    </a-modal>
  </a-spin>
</template>

<script>
let _this;
import VueUeditorWrap from "vue-ueditor-wrap";
import EditableCell from "@/components/EditTableCell.vue";
import category from "@/api/cms/category";
import newsArticle from "@/api/cms/newsArticle";
import request from "@/request";
export default {
  name: "NewsArticleInfo",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      auditModal: {
        info: null,
        visible: false,
      },
      content: "",
      previewAction: false,
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      categoryTree: [],
      articleTypeList: [],
      pictureTypeList: [],
      tagList: [],
      form: {
        categoryId: [],
        articleType: 1,
        pictureType: 0,
        pictures: [],
        videoUrl: "",
        status: true,
        recommend: false,
        imgList: [],
        tag: [],
        publishTime: null,
        cancelTime: null,
      },
      rules: {
        categoryId: [
          {
            required: true,
            message: "请选择所属分类",
            trigger: "change",
          },
        ],
        title: [
          {
            required: true,
            message: "请输入资讯标题",
            trigger: "blur",
          },
        ],
        articleType: [
          {
            required: true,
            message: "请选择资讯类型",
            trigger: "change",
          },
        ],
        // pictureType: [
        //   {
        //     required: true,
        //     message: "请选择封面图类型",
        //     trigger: "change",
        //   },
        // ],
      },
      myConfig: {
        autoHeightEnabled: false,
        initialFrameHeight: 600,
        wordCount: true,
        maximumWords: 100000,
        initialFrameWidth: "100%",
        serverUrl: "",
        UEDITOR_HOME_URL: "/UEditor/",
        toolbars: this.$config.editorConfig.toolbars,
      },
      uploadFileUrl: "",
      previewVisible: false,
      previewImage: "",
      fileList: [],
      videoFileList: [],
      imgUploading: false,
      imgTable: {
        columns: [
          {
            dataIndex: "sortNo",
            title: "序号",
            width: 50,
            align: "center",
          },
          {
            dataIndex: "path",
            title: "图片",
            width: 180,
            align: "center",
            scopedSlots: { customRender: "path" },
          },
          {
            key: "introduce",
            title: "图片简介",
            align: "center",
            scopedSlots: { customRender: "introduce" },
          },
          {
            key: "action",
            title: "操作",
            width: 180,
            align: "center",
            scopedSlots: { customRender: "action" },
          },
        ],
      },
    };
  },
  components: {
    VueUeditorWrap,
    EditableCell,
  },
  mounted() {
    _this = this;
    _this.uploadFileUrl = request.getUploadUrl("cms");
    _this.myConfig.serverUrl = request.getUploadUrl("cms");
    _this.getArticleTypes();
    _this.getPictureTypes();
    _this.getCategoryTree();
    if (_this.info.id != 0) {
      //编辑
      _this.getInfo();
    } else {
      _this.form.status = 0;
      if (_this.info.selectTypeId) {
        _this.getParent(_this.info.selectTypeId);
      }
    }
  },
  methods: {
    showaudit() {
      this.auditModal.info = this.form;
      this.auditModal.visible = true;
    },
    auditCallBack(data) {
      this.auditModal.visible = false;
      this.$emit("callback", data != null);
    },
    timeChange(date, dateString) {
      _this.form.publishTime = dateString;
    },
    disabledDateTime(val) {
      const startVal = _this.form.publishTime
        ? _this.$moment(_this.form.publishTime, "YYYY-MM-DD")
        : _this.$moment();
      if (!val || !startVal) {
        return false;
      }
      return val.valueOf() <= startVal.valueOf();
    },
    presetDate(num) {
      let startDate = _this.form.publishTime;
      if (startDate) {
        startDate = _this.$moment(startDate, "YYYY-MM-DD");
      } else {
        startDate = _this.$moment();
      }
      _this.form.cancelTime = startDate.add(num, "days");
    },
    async getArticleTypes() {
      let result = await newsArticle.getArticleTypes();
      if (result.errorCode == _this.$commons.RespCode.success) {
        _this.articleTypeList = result.data;
      } else {
        _this.$message.error(result.errorMsg);
      }
    },
    async getPictureTypes() {
      let result = await newsArticle.getPictureTypes();
      if (result.errorCode == _this.$commons.RespCode.success) {
        _this.pictureTypeList = result.data;
      } else {
        _this.$message.error(result.errorMsg);
      }
    },
    async getCategoryTree() {
      let result = await category.getTree(false);
      if (result.errorCode == _this.$commons.RespCode.success) {
        _this.categoryTree = result.data;

        // 职工园地特殊处理
        if (_this.info.specialTypeId) {
          _this.categoryTree = _this.categoryTree.filter(
            (item) => item.id == _this.info.specialTypeId
          );
        }
      } else {
        _this.$message.error(result.errorMsg);
      }
    },
    /**回显栏目 */
    async getParent(id) {
      let result = await category.getParents(id);
      if (result.errorCode == _this.$commons.RespCode.success) {
        _this.form.categoryId = result.data;
      } else {
        _this.$message.error(result.errorMsg);
      }
    },
    htmlDecode(text) {
      if (text) {
        if (text.indexOf("lt", "gt", "amp", "quot") > -1) {
          var temp = document.createElement("div");
          temp.innerHTML = text;
          var output = temp.innerHTML;
          temp = null;
          // 去掉标签中的"\"
          var str = output.replace(/\\/g, "");
          // 标点替换
          str = str.replace(/&ldquo;/g, "“");
          str = str.replace(/&amp;ldquo;/g, "“");
          str = str.replace(/&rdquo;/g, "”");
          str = str.replace(/&amp;rdquo;/g, "”");
          str = str.replace(/&laquo;/g, "«");
          str = str.replace(/&raquo;/g, "»");
          str = str.replace(/&lsquo;/g, "‘");
          str = str.replace(/&rsquo/g, "’");
          str = str.replace(/&lt;/g, "<");
          str = str.replace(/&gt;/g, ">");
          str = str.replace(/&hellip;/g, "”");
          str = str.replace(/&rdquo;/g, "…");
          return str;
        } else {
          return text;
        }
      } else {
        return text;
      }
    },
    /**获取详情 */
    getInfo() {
      _this.isloading = true;
      newsArticle
        .getById(_this.info.id)
        .then((res) => {
          if (res.errorCode == _this.$commons.RespCode.success) {
            if (res.data.content) {
              _this.content = _this.htmlDecode(res.data.content);
            }
            // _this.content = res.data.content;
            if (res.data.pictures) {
              res.data.pictures = res.data.pictures.split(",");
              res.data.pictures.forEach(function (item, index) {
                _this.fileList.push({
                  name: `image_${index + 1}`,
                  response: {
                    data: { name: item, path: item },
                  },
                  uid: `image_${index + 1}`,
                  thumbUrl: item,
                });
              });
            } else {
              res.data.pictures = [];
            }
            if (res.data.videoUrl) {
              _this.videoFileList.push({
                name: `video_1`,
                response: {
                  data: { name: res.data.videoUrl, path: res.data.videoUrl },
                },
                uid: `video_1`,
                thumbUrl: res.data.videoUrl,
              });
            }
            if (res.data.tag) {
              res.data.tag = res.data.tag.split(",");
            }
            _this.form = {
              ...res.data,
              content: _this.content,
              categoryId: [res.data.categoryId],
              pictures: _this.fileList.map((item) => item.thumbUrl),
              publishTime: res.data.publishTime ? res.data.publishTime : null,
              cancelTime: res.data.cancelTime ? res.data.cancelTime : null,
            };
            _this.form.recommend = _this.$util.int2Boolean(res.data.recommend);
            _this.form.status = _this.$util.int2Boolean(res.data.status);
            _this.getParent(res.data.categoryId);
            if (
              this.info &&
              this.info.taskId &&
              this.info.taskId != this.form.taskId
            ) {
              this.form.taskId = this.info.taskId;
            }
            _this.isloading = false;
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    preview() {
      _this.previewAction = true;
      _this.$refs.articleForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          if (_this.form.pictureType > 0 && _this.form.pictures.length == 0) {
            _this.$message.error("请上传封面图");
            _this.isloading = false;
            return false;
          }
          if (_this.form.pictureType == 4 && _this.form.pictures.length < 3) {
            _this.$message.error("请上传三张封面图");
            _this.isloading = false;
            return false;
          }
          if (_this.form.articleType == 3 && _this.form.imgList.length == 0) {
            _this.$message.error("请上传图集图片");
            _this.isloading = false;
            return false;
          }
          let formData = {
            ..._this.form,
            categories: [],
            content: _this.content,
          };
          formData.categories.push({
            id: formData.categoryId[formData.categoryId.length - 1],
          });
          delete formData.categoryId;
          formData.recommend = _this.$util.boolean2Int(_this.form.recommend);
          formData.status = _this.$util.boolean2Int(_this.form.status);
          formData.pictures = _this.form.pictures.join();
          formData.tag = formData.tag ? formData.tag.join(",") : "";
          if (_this.form.articleType == 3) {
            formData.imgListJSON = JSON.stringify(_this.form.imgList);
            delete formData.imgList;
          }
          if (_this.form.articleType == 1) {
            delete formData.imgList;
          }

          if (_this.info.id != 0) {
            formData.id = _this.info.id;
          }

          // 添加、修改
          newsArticle
            .preview(formData)
            .then((res) => {
              if (res.errorCode == _this.$commons.RespCode.success) {
                window.open(
                  _this.$config.previewDomain +
                    "/information-detail.html?access_token=" +
                    this.$store.getters["currentUser/getToken"]
                );
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    /**提交审核 */
    handleSubmit(status) {
      _this.previewAction = false;
      _this.$refs.articleForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          if (_this.form.pictureType > 0 && _this.form.pictures.length == 0) {
            _this.$message.error("请上传封面图");
            _this.isloading = false;
            return false;
          }
          if (_this.form.pictureType == 4 && _this.form.pictures.length < 3) {
            _this.$message.error("请上传三张封面图");
            _this.isloading = false;
            return false;
          }
          if (_this.form.articleType == 3 && _this.form.imgList.length == 0) {
            _this.$message.error("请上传图集图片");
            _this.isloading = false;
            return false;
          }
          let formData = {
            ..._this.form,
            categories: [],
            content: _this.content,
          };
          formData.categories.push({
            id: formData.categoryId[formData.categoryId.length - 1],
          });
          delete formData.categoryId;
          formData.recommend = _this.$util.boolean2Int(_this.form.recommend);
          formData.status = status;
          formData.pictures = _this.form.pictures.join();
          formData.tag = formData.tag ? formData.tag.join(",") : "";
          if (_this.form.articleType == 3) {
            formData.imgListJSON = JSON.stringify(_this.form.imgList);
            delete formData.imgList;
          }
          if (_this.form.articleType == 1) {
            delete formData.imgList;
          }

          if (_this.info.id != 0) {
            formData.id = _this.info.id;
          }

          newsArticle
            .saveOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == _this.$commons.RespCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callback", true);
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      _this.previewVisible = false;
    },
    handlePreview(file) {
      _this.previewImage = file.url || file.thumbUrl;
      _this.previewVisible = true;
    },
    beforeUpload(file) {
      let isJPGPNG = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        _this.$message.error("只能上传jpg/png格式的文件!");
      }
      let isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        _this.$message.error("文件不能超过2MB!");
      }
      return isJPGPNG && isLt2M;
    },
    beforeVideoUpload(file) {
      let mp4 = false;
      if (file.type === "video/mp4") {
        mp4 = true;
      } else {
        mp4 = false;
        _this.$message.error("只能上传mp4格式的文件!");
      }
      let isLt = file.size / 1024 / 1024 < 500;
      if (!isLt) {
        _this.$message.error("文件不能超过500MB!");
      }
      return mp4 && isLt;
    },
    handleFileChange(fileInfo) {
      _this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "done") {
        if (fileInfo.file.response.state != "SUCCESS") {
          _this.$message.error("上传失败");
        } else {
          _this.form.pictures.push(fileInfo.file.response.url);
          fileInfo.file.thumbUrl = fileInfo.file.response.url;
        }
      } else if (fileInfo.file.status == "removed") {
        _this.form.pictures.splice(
          _this.form.pictures.indexOf(fileInfo.file.thumbUrl),
          1
        );
      } else if (fileInfo.file.status == "error") {
        _this.$message.error("上传失败");
      }
    },
    handleVideoFileChange(fileInfo) {
      _this.videoFileList = fileInfo.fileList;
      if (fileInfo.file.status == "done") {
        if (
          fileInfo.file.response.errorCode != _this.$commons.RespCode.success
        ) {
          _this.$message.error("上传失败");
        } else {
          _this.form.videoUrl = fileInfo.file.response.data.path;
        }
      } else if (fileInfo.file.status == "removed") {
        _this.form.videoUrl = "";
      } else if (fileInfo.file.status == "error") {
        _this.$message.error("上传失败");
      }
    },
    handleImgListChange(fileInfo) {
      if (fileInfo.file.status == "uploading" && !_this.imgUploading) {
        _this.imgUploading = true;
      } else if (fileInfo.file.status == "done") {
        if (
          fileInfo.file.response.errorCode != _this.$commons.RespCode.success
        ) {
          _this.$message.error("上传失败");
        } else {
          _this.$message.success("上传成功");
          let no = _this.form.imgList.length;
          _this.form.imgList.push({
            path: fileInfo.file.response.data.path,
            introduce: "",
            sortNo: no + 1,
          });
          _this.imgUploading = false;
        }
      } else if (fileInfo.file.status == "error") {
        _this.$message.error("上传失败");
        _this.imgUploading = false;
      }
    },
    onCellChange(sortNo, dataIndex, value) {
      let imgTextList = _this.form.imgList;
      let target = imgTextList.find((item) => item.sortNo == sortNo);
      if (target) {
        target[dataIndex] = value;
        _this.form.imgList = imgTextList;
      }
    },
    removeImg(sortNo) {
      let imgTextList = _this.form.imgList;
      _this.form.imgList = imgTextList.filter((item) => item.sortNo != sortNo);
      _this.form.imgList.map((item) => {
        if (item.sortNo > sortNo) {
          item.sortNo = item.sortNo - 1;
        }
      });
    },
    upOrDown(index1, index2) {
      let curr = _this.form.imgList.splice(index1, 1, {
        ..._this.form.imgList[index2],
        sortNo: index1 + 1,
      })[0];
      _this.form.imgList.splice(index2, 1, {
        ...curr,
        sortNo: index2 + 1,
      });
    },
    handleUp(sortNo) {
      if (sortNo > 1) {
        _this.upOrDown(sortNo - 1, sortNo - 2);
      }
    },
    handleDown(sortNo) {
      if (sortNo < _this.form.imgList.length) {
        _this.upOrDown(sortNo - 1, sortNo);
      }
    },
    /**取消 */
    cancelClick() {
      _this.$emit("callback", false);
    },
  },
};
</script>
<style>
.ant-calendar-footer-extra span {
  margin: 0 9px;
  cursor: pointer;
}
#content div {
  line-height: 1.5;
}
.custom-form-explain .ant-form-explain {
  color: red;
}
</style>
