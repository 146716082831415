/*
 * @Author: 贾晓红 
 * @Date: 2019-09-17 14:07:28 
 * @Last Modified by: 杜现鹏
 * @Last Modified time: 2023-06-13 17:24:03
 */

import request from '@/request';
const part_url = "/share/cms";
/**
 *    分页列表
 *
 * @param {*} pageNow
 * @param {*} pageSize
 * @param {*} title  标题
 * @param {*} categoryName 分类名称
 * @param {*} categoryId  分类id
 * @param {*} tag  标签
 * @param {*} auditStatus  审核状态
 * @param {*} recommend  是否推荐
 * @param {*} status  状态
 */
function pageList(pageNow, pageSize, newsArticle) {
    return request.get(part_url+"/article/getPageList", { pageNow, pageSize, ...newsArticle })

}

/**
 *获取详情
 *
 * @param {*} id
 * @returns
 */
function getById(id,view) {
    return request.get(part_url+'/article/getById', { id,view })
}

function getByIdForApp(id,view,appKey) {
    return request.get(part_url+'/article/getById', { id,view,appKey })
}

/**
 *审核
 *
 * @param {*} ids
 * @param {*} auditStatus
 * @param {*} auditRemark
 * @returns
 */
function audit(ids, auditStatus, auditRemark) {
    return request.get(part_url+'/article/audit', { ids, auditStatus, auditRemark })
}

/**
 *删除
 *
 * @param {*} ids
 * @returns
 */
function removeByIds(ids) {
    return request.get(part_url+'/article/removeByIds', { ids })
}

/**
 *修改状态
 *
 * @param {*} id
 * @param {*} status
 * @returns
 */
function updateStatus(ids, status) {
    return request.get(part_url+'/article/updateStatus', { ids, status })
}
/**
 * 推荐
 * @param {*} ids 
 * @param {*} recommend 
 */
function recommend(ids, recommend) {
    return request.get(part_url+'/article/recommend', { ids, recommend })
}


/**
 *保存
 *
 * @param {*} title 标题
 * @param {*} tag 标签
 * @param {*} categoryId 分类
 * @param {*} content 内容
 * @param {*} author 作者
 * @param {*} pictures 封面图
 * @param {*} summary 资讯概述
 * @param {*} jumpAddress 链接地址
 * @returns
 */
function saveOrUpdate(newsArticle) {
    return request.post(part_url+'/article/saveOrUpdate', {}, newsArticle);
}
/**
 *
 *置顶
 * @returns
 */
function top(id) {
    return request.get(part_url+'/article/top', { id })
}

/**
 * 取消置顶
 * @param id
 */
function cancelTop(id) {
    return request.get(part_url+'/article/cancelTop', { id })
}
/**
 *
 *上移下移
 */
function upOrDown(id, up) {
    return request.get(part_url+'/article/upOrDown', { id, up })
}
/**
 * 文章类型
 */
function getArticleTypes() {
    return request.get(part_url+'/article/getArticleTypes', {})
}
/**
 * 封面图类型
 */
function getPictureTypes() {
    return request.get(part_url+'/article/getPictureTypes', {})
}

function preview(data){
    return request.post(part_url+'/article/setPreviewData', {}, data);
}

function pageListForFront(pageNow, pageSize, newsArticle) {
    return request.get(part_url+"/article/getPageListForFront", { pageNow, pageSize, ...newsArticle })

}

export default {
    pageListForFront,
    preview,
    pageList,
    getById,
    audit,
    removeByIds,
    updateStatus,
    saveOrUpdate,
    top,
    cancelTop,
    upOrDown,
    getArticleTypes,
    getPictureTypes,
    recommend,
    getByIdForApp
}